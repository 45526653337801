import styled from 'styled-components';
    
export const Button = styled.div`
   width: 100%;
   cursor: pointer;
   display: flex;
   justify-content: center;
   gap: 12px;
   padding: 8px;
    border: 1px solid white;
    align-items: center;
    border-radius: 12px;
    box-shadow: 1px 1px 4px -1px white;
    margin: 48px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: sans-serif;
`