import aparelho_ortodontico from '../../../Assets/aparelho_ortodontico.webp'
import aligner from '../../../Assets/aligner.webp'
import clareamento from '../../../Assets/clareamento.webp'
import limpeza from '../../../Assets/limpeza_profilaxia.webp'
import urgencia from '../../../Assets/urgencia.webp'
import harmonizacao from '../../../Assets/harmonizacao.webp'
import lentes_contato from '../../../Assets/lentes_contato.webp'
import avaliacao from '../../../Assets/avaliacao.webp'
import implante from '../../../Assets/implante.webp'
    
export const allActions = [
        {
            "actions": "Avaliação",
            "image": avaliacao
        },
        {
            "actions": "Implante",
            "image": implante
        },
        {
            "actions": "Aparelho ortodôntico",
            "image": aparelho_ortodontico
        },
        {
            "actions": "Urgência",
            "image": urgencia
        },
        {
            "actions": "Aligner",
            "image": aligner
        },
        {
            "actions": "Profilaxia",
            "image": limpeza
        },
        {
            "actions": "Clareamento",
            "image": clareamento
        },
        {
            "actions": "Lentes de contato",
            "image": lentes_contato
        },
        {
            "actions": "harmonização",
            "image": harmonizacao
        }
    ];
  

